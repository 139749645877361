
import { defineComponent, getCurrentInstance, PropType } from 'vue'
import * as _ from 'lodash'
import CopyField from '../../../../CopyField.vue'
import { ManualCommunication } from '@/types/GCB2'
import { useTaskListBatchModeStore } from '@/store/stores/gcb2/taskListBatchMode'
import MessageTextEditor from '../../../../inputs/MessageTextEditor/MessageTextEditor.vue'
import { CONNECTOR_TYPES } from '../../../../../vars/general'
import useRouter from '../../../../../hooks/useRouter'
export default defineComponent({
    props: {
        message: {
            requried: true,
            type: Object as PropType<ManualCommunication>,
            default: () => {},
        },
        allMediaLoading: { type: Boolean, default: false },
    },
    components: { CopyField, MessageTextEditor },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const { updateMessage } = useTaskListBatchModeStore()
        const gcb2Connector = root.$store.getters.connectorByType({
            connectorType: CONNECTOR_TYPES.GCB2,
            projectId: route.value.params.id,
        })
        const label = 'РЕКОМЕНДУЕМЫЙ СКРИПТ:'
        const changeTextMessage = _.debounce((e: string) => {
            updateMessage({ communicationId: props.message.communicationId, message: e })
            emit('message-changed', props.message.communicationId)
        }, 100)
        const setActiveItem = (item: any) => {
            emit('set-item', item)
        }
        const setLoading = (value: boolean) => {
            emit('set-loading', value)
        }
        return { gcb2Connector, label, changeTextMessage, setActiveItem, setLoading }
    },
})
